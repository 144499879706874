import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import { DateTime } from 'luxon';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
// import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
// import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';

// SyntaxHighlighter.registerLanguage('bash', bash);
// SyntaxHighlighter.registerLanguage('javascript', js);

// markup
const IndexPage = ({ data, location }) => {
  let matomo = data.matomo.nodes[0];
  let themes = data.themes.nodes.length;
  let plugins = data.plugins.nodes.length;
  let premiumPlugins = data.pluginsPayed.nodes.length;
  let premiumBundles = data.bundlesPayed.nodes.length;

  let lastUpdated = DateTime.fromISO(matomo.lastUpdated).toFormat(
    `MMMM d, yyyy`
  );

  return (
    <Layout location={location}>
      <title>Matomo packages</title>
      <h1>Matomo packages</h1>
      <p>
        This site mirrors the Matomo plugin and theme directories as a Composer
        repository.
      </p>
      <p>
        {`The latest stable version of Matomo is ${matomo.latestVersion} and it was release on ${lastUpdated}`}
        .
      </p>
      <p>{`There are currently:`}</p>
      <ul>
        <li>
          {`${themes} `}
          <Link
            partiallyActive={true}
            activeClassName="active"
            to="themes"
          >{`themes`}</Link>
        </li>
        <li>
          {`${plugins} `}{' '}
          <Link
            partiallyActive={true}
            activeClassName="active"
            to="plugins"
          >{`plugins`}</Link>
        </li>
      </ul>
      <ul>
        <li>
          {`${premiumPlugins} `}
          <Link
            activeClassName="active"
            to="premium-plugins"
          >{`premium plugins`}</Link>
        </li>
        <li>
          {`${premiumBundles} `}
          <Link
            activeClassName="active"
            to="premium-bundles"
          >{`premium bundles`}</Link>
        </li>
      </ul>
      <p>Add the following snippet to your composer.json file: </p>
      <SyntaxHighlighter language="javascript">
        {`...
      "repositories": {
          "composer.analys.cloud": {
              "type": "composer",
              "url": "https://composer.analys.cloud/"
          }
      }
...`}
      </SyntaxHighlighter>
      <p>
        And you will be able to require matomo core, plugins, themes with and a
        command such as:{' '}
      </p>
      <SyntaxHighlighter language="bash">{`composer require matomo/matomo`}</SyntaxHighlighter>
      or
      <SyntaxHighlighter language="bash">{`composer require matomo/matomo:^${matomo.latestVersion}`}</SyntaxHighlighter>
    </Layout>
  );
};

export const query = graphql`
  {
    matomo: allMatomo {
      nodes {
        id
        latestVersion
        lastUpdated
      }
    }
    themes: allMatomoTheme(sort: { order: DESC, fields: lastUpdated }) {
      nodes {
        id
      }
    }
    plugins: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: { isPaid: { eq: false } }
    ) {
      nodes {
        id
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
      }
    }
    pluginsPayed: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: { isPaid: { eq: true }, isBundle: { in: false } }
    ) {
      nodes {
        id
      }
    }
    bundlesPayed: allMatomoPlugin(
      sort: { order: DESC, fields: lastUpdated }
      filter: { isPaid: { eq: true }, isBundle: { in: true } }
    ) {
      nodes {
        id
      }
    }
  }
`;

export default IndexPage;
